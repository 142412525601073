import * as React from 'react'
import { kbImagifyOrig, kbImagifyDyn } from '../shared/helpers'

export const ImgElement = ({ file }) => {
  //   console.log(file)
  return (
    <a
      href={kbImagifyOrig(file)}
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginRight: '2px' }}
    >
      <img
        src={kbImagifyDyn(file)}
        style={{ height: '50px', width: 'auto' }}
        alt="."
      />
    </a>
  )
}

const ImageField = ({ record, source }) => {
  if (!record || !record[source]) return null
  if (Array.isArray(record[source]))
    return (
      <>
        {record[source].map((file) => (
          <ImgElement key={file} file={file} />
        ))}
      </>
    )
  if (typeof record[source] === 'string')
    return <ImgElement file={record[source]} />
}

export default ImageField
