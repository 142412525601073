import * as React from 'react'
import { Edit, TextInput, BooleanInput, SimpleForm } from 'react-admin'
import { SharedFields } from './ArtistCreate'
import { Box } from '@material-ui/core'

const ArtistEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <Box display="flex" style={{ width: '60%' }}>
        <TextInput disabled source="artistId" label="Künstler-ID" />
        <TextInput
          style={{ marginLeft: '0.5em', marginRight: '1em' }}
          disabled
          source="id"
          label="Datenbank-ID"
        />
        <BooleanInput label="On-/Offline" source="visible" />
      </Box>
      <SharedFields />
    </SimpleForm>
  </Edit>
)

export default ArtistEdit
