import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  RAFirebaseOptions,
} from 'react-admin-firebase'

import { Login, Layout } from './layout'
import { firebaseConfig } from './FIREBASE_CONFIG'
import { firebase as firebaseInstance } from './firebase'

import artists from './artists/index'
import artworks from './artworks/index'
import purchaseRequests from './purchaseRequests/index'

const options: RAFirebaseOptions = {
  logging: true,
  softDelete: true,
  app: firebaseInstance,
  // rootRef: "root_collection/some_document",
  // watch: ["posts"]
}

const dataProvider = FirebaseDataProvider(firebaseConfig, options)
const authProvider = FirebaseAuthProvider(firebaseConfig, options)

function App(): JSX.Element {
  return (
    <Admin
      title="KUBOSHOW GDT"
      dataProvider={dataProvider}
      // customReducers={{ theme: themeReducer }}
      authProvider={authProvider}
      loginPage={Login}
      layout={Layout}
      disableTelemetry
    >
      <Resource name="artists" {...artists} />
      <Resource name="artworks" {...artworks} />
      <Resource name="purchaseRequests" {...purchaseRequests} />
    </Admin>
  )
}

export default App
