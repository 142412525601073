import { groupByKey } from './helpers'
import { currentFair } from './bigExport'
import { firestore } from '../firebase'

export const getArtists = async () => {
  const artistsObj = {}
  try {
    const artistsSnapshot = await firestore
      .collection('artists')
      // .where('first_name', '==', 'Ilze')
      .get()
    if (artistsSnapshot.empty) {
      console.log('No matching documents.')
      return
    }
    artistsSnapshot.forEach((doc) => {
      const docData = doc.data()
      const docId = doc.id
      if (!docData.deleted) {
        artistsObj[docId] = docData
      }
    })
    return artistsObj
  } catch (error) {
    console.error(error)
  }
}

export const getFilteredArtworks = async () => {
  const artworksArray = []
  try {
    const artworksSnapshot = await firestore
      .collection('artworks')
      .where('fair', '==', currentFair)
      .get()
    if (artworksSnapshot.empty) {
      console.log('No matching documents.')
      return
    }
    artworksSnapshot.forEach((doc) => {
      const docData = doc.data()
      const docId = doc.id
      if (!docData.deleted) {
        artworksArray.push({ id: docId, ...docData })
      }
    })
    return artworksArray
  } catch (error) {
    console.error(error)
  }
}

export const transformArtistsObj = async (artistsObjInput) => {
  const artworksArray = await getFilteredArtworks()
  const artworksByArtist = groupByKey(artworksArray, 'artistId')
  console.log('artworksByArtist', artworksByArtist)

  const filteredArtistsObj = Object.entries(artistsObjInput).filter(([docId]) =>
    Object.keys(artworksByArtist).includes(docId)
  )

  console.log(filteredArtistsObj.length)

  const result = filteredArtistsObj
    .reduce((artistObj, [docId, artistData]) => {
      const {
        done212,
        done21,
        userAgent,
        id,
        previewImage,
        // ContractData:
        account_holder,
        iban,
        bic,
        bank_name,
        tax_number,
        tax_office,
        liability,
        consentGTC,
        // unneeded data for urgent temp solution
        // vita_short,
        // vita_long,
        birthday,
        instagram,
        email,
        homepage,
        info,
        ...restArtistData
      } = artistData

      const artistsArtworksByFair = groupByKey(artworksByArtist[docId], 'fair')

      artistObj[docId] = {
        ...restArtistData,
        artworks: artistsArtworksByFair[currentFair].map(
          (artwork) => {
            const { title, files, catalog = false, catalogTop = false } = artwork
            if (!files[0] || !title) {
              console.warn('No files for', artwork)
              return null
            }

            return {
            title,
            path: files[0],
            finalName: `${artistData.last_name} ${artistData.first_name}/${
              catalogTop ? 'XXX-' : catalog ? 'X-' : ''
            }${title}.${files[0].split('.').pop()}`,
          }
          }
        ).filter((artwork) => artwork !== null),
      }
      return artistObj
    }, {})

  return result
}
