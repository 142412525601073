import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  // avatar: {
  //     marginRight: theme.spacing(1),
  //     marginTop: -theme.spacing(0.5),
  //     marginBottom: -theme.spacing(0.5),
  // },
}))

// const AvatarField = ({ record, size = '25', className }) =>
//     record ? (
//         <Avatar
//             src={`${record.avatar}?size=${size}x${size}`}
//             style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
//             className={className}
//         />
//     ) : null;

const FullNameField = ({ record }) => {
  const classes = useStyles()
  return record ? (
    <div className={classes.root}>
      {/* <AvatarField
                className={classes.avatar}
                record={record}
                size={size}
            /> */}
      {record.last_name} {record.first_name}
    </div>
  ) : null
}

// FullNameField.defaultProps = {
//   source: 'last_name',
//   label: 'resources.customers.fields.name',
// }

const ArtistLinkField = (props) =>
  props.record ? <FullNameField {...props} /> : null

// ArtistLinkField.defaultProps = {
//     source: 'customer_id',
//     addLabel: true,
// }

export default ArtistLinkField
