import * as React from 'react'
import {
  List,
  Datagrid,
  DateField,
  BooleanField,
  TextField,
  EmailField,
  ReferenceField,
} from 'react-admin'
import ArtistLinkField from '../artworks/ArtistLinkField'
// import { ArtistListFilter } from '../artworks/ArtworkList'
import { ImgElement } from '../shared/ImageField'
import { calcPrice, exportToXLSX, getArtworkDimension } from '../shared/helpers'

export const prExporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, 'artistId', 'artists').then((artists) => {
    fetchRelatedRecords(records, 'artworkId', 'artworks').then((artworks) => {
      const recordsForExport = records.map((record) => {
        const {
          // omit fields:
          id,
          consentChecked,
          userAgent,
          artistId,
          artworkId,
          customerMailSent,
          customerMailTodo,
          wennrichMailSent,
          wennrichTodo,
          updatedAt,
          updatedby,
          lastupdate,
          ...rest
        } = record
        // postForExport.author_name = post.author.name; // add a field
        // console.log(record, artists[record.artistId])
        try {
          rest.artist_last_name = artists[record.artistId].last_name
          rest.artist_first_name = artists[record.artistId].first_name
          rest.artwork_title = artworks[record.artworkId].title
          rest.artwork_technique = artworks[record.artworkId].technique
          rest.artwork_dimensions = getArtworkDimension(
            artworks[record.artworkId]
          )
          rest.artwork_width = artworks[record.artworkId].width
          rest.artwork_height = artworks[record.artworkId].height
          rest.artwork_depth = artworks[record.artworkId].depth
          rest.artwork_fullPrice = calcPrice(artworks[record.artworkId])
        } catch (error) {
          console.log(
            error,
            record,
            artists[record.artistId],
            artworks[record.artworkId]
          )
        }
        return rest
      })

      exportToXLSX(
        recordsForExport.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ),
        // recordsForExport.sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
        [
          // order fields in the export
          'createdAt',
          'last_name',
          'first_name',
          'email',
          'phone',
          'artist_last_name',
          'artist_first_name',
          'artwork_title',
          'artwork_technique',
          'artwork_dimensions',
          'artwork_width',
          'artwork_height',
          'artwork_depth',
          'artwork_fullPrice',
        ],
        'Kaufanträge',
        `KUBOSHOW 2021 GDT Kaufanträge ${new Date().toISOString()}`
      )
    })
  })
}

const ArtworkLinkField = ({ record }) => {
  if (!record) return null
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ImgElement file={record.files[0]} />
      <span style={{ marginLeft: '5px' }}>{record.title}</span>
    </div>
  )
}
const ArtworkPriceField = ({ record }) => {
  if (!record) return null
  return (
    <div>
      <span style={{ marginLeft: '5px' }}>{calcPrice(record)}</span>
    </div>
  )
}

const PurchaseRequestList = (props) => (
  <List
    perPage={50}
    exporter={prExporter}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      {/* <TextField disabled source="id" /> */}
      <DateField source="createdAt" label="eingegangen" showTime={true} />
      <TextField source="last_name" label="Nachname" />
      <TextField source="first_name" label="Vorname" />
      <EmailField source="email" label="E-Mail" />
      <TextField source="phone" label="Telefonnr." />
      <ReferenceField label="Künstler" source="artistId" reference="artists">
        <ArtistLinkField />
      </ReferenceField>
      <ReferenceField label="Arbeit" source="artworkId" reference="artworks">
        <ArtworkLinkField />
      </ReferenceField>
      <ReferenceField label="Price" source="artworkId" reference="artworks">
        <ArtworkPriceField />
      </ReferenceField>
      <BooleanField label="Mail Kunde verschickt" source="customerMailSent" />
      <BooleanField
        label="Mail Wennrich verschickt"
        source="wennrichMailSent"
      />
    </Datagrid>
  </List>
)

export default PurchaseRequestList
