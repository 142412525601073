/* eslint-disable import/no-anonymous-default-export */
import UserIcon from '@material-ui/icons/People'

import ArtistList from './ArtistList'
import ArtistCreate from './ArtistCreate'
import ArtistEdit from './ArtistEdit'
import ArtistShow from './ArtistShow'

export default {
  list: ArtistList,
  create: ArtistCreate,
  edit: ArtistEdit,
  show: ArtistShow,
  icon: UserIcon,
}
