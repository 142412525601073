/* eslint-disable import/no-anonymous-default-export */
import ShoppingCart from '@material-ui/icons/ShoppingCart'

import PurchaseRequestList from './PurchaseRequestList'
// import PurchaseRequestCreate from './PurchaseRequestCreate'
// import PurchaseRequestEdit from './PurchaseRequestEdit'
// import PurchaseRequestShow from './PurchaseRequestShow'

export default {
  list: PurchaseRequestList,
  // create: PurchaseRequestCreate,
  // edit: PurchaseRequestEdit,
  // show: PurchaseRequestShow,
  icon: ShoppingCart,
  options: { label: 'Kaufanträge' },
}
