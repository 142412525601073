import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { firebaseConfig } from './FIREBASE_CONFIG.js'

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

// Get a reference to the firestore
const firestore = firebase.firestore()

// Get a reference to the storage service
const storage = firebase.storage()

// Create a storage reference from our storage service
const storageRef = storage.ref()

// Points to 'images'
const imagesRef = storageRef.child('images')

export { firebase, firestore, storage, storageRef, imagesRef }
