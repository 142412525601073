/* eslint-disable import/no-anonymous-default-export */
import PhotoLibrary from '@material-ui/icons/PhotoLibrary'

import ArtworkList from './ArtworkList'
// import ArtworkCreate from './ArtworkCreate'
// import ArtworkEdit from './ArtworkEdit'
// import ArtworkShow from './ArtworkShow'

export default {
  list: ArtworkList,
  // create: ArtworkCreate,
  // edit: ArtworkEdit,
  // show: ArtworkShow,
  icon: PhotoLibrary,
}
