import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { storageRef } from '../firebase'
import { getArtists, transformArtistsObj } from './migrate'
import { Document, Packer, Paragraph, TextRun } from 'docx'

export const currentFair = '2021-2'

const dateInFilename = new Date()
  .toISOString()
  .replace(/[^T0-9]/g, '')
  .slice(0, 15)

const generateDoc = (vita) => {
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: vita.split('\n').map(
          (p) =>
            new Paragraph({
              children: [new TextRun(p)],
            })
        ),
      },
    ],
  })
  return doc
}

export const downloadFolderAsZip = async () => {
  const artistsObj = await getArtists()
  const migratedArtistsObj = await transformArtistsObj(artistsObj)
  console.log('migratedArtistsObj RIGHT BEFORE export', migratedArtistsObj)
  // const artistsOf2022 = Object.keys(migratedArtistsObj)

  const jszip = new JSZip()

  const promises = Object.values(migratedArtistsObj)
  .slice(60)
    .map((artistData: any) => {
      return [
        {
          finalName:
            `${artistData.last_name} ${artistData.first_name}/` +
            'PORTRAITFOTO.' +
            artistData.portrait_file.split('.').pop(),
          path: artistData.portrait_file,
        },
        {
          finalName:
            `${artistData.last_name} ${artistData.first_name}/` +
            'VITA_KURZ.docx',
          vitaDoc: generateDoc(artistData.vita_short),
        },
        {
          finalName:
            `${artistData.last_name} ${artistData.first_name}/` +
            'VITA_LANG.docx',
          vitaDoc: generateDoc(artistData.vita_long),
        },
        ...artistData.artworks,
      ]
    })
    .flat()
    .map(async (filePromise) => {
      let fileBlob
      if (filePromise.vitaDoc) {
        fileBlob = await Packer.toBlob(filePromise.vitaDoc)
      } else {
        const fileRef = storageRef.child(`images/${filePromise.path}`)
        const fileUrl = await fileRef.getDownloadURL()
        fileBlob = await fetch(fileUrl).then((response) => response.blob())
      }
      console.info(filePromise.finalName || filePromise.path)
      jszip.file(filePromise.finalName, fileBlob)
    })
    .reduce((acc, curr) => acc.then(() => curr), Promise.resolve())

  await promises
  const blob = await jszip.generateAsync({ type: 'blob' })
  saveAs(blob, `KUBOSHOW ${currentFair} EXPORT ${dateInFilename}.zip`)
}
