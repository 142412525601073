/* eslint-disable no-unused-vars */
import * as React from 'react'
import {
  List,
  Datagrid,
  DateField,
  TextField,
  EmailField,
  BooleanField,
  Filter,
  // SelectInput,
  BooleanInput,
} from 'react-admin'
import { OnlineOfflineField } from '../artworks/ArtworkList'

import { exportToXLSX } from '../shared/helpers'
import ImageField from '../shared/ImageField'
// import ArtistShow from './ArtistShow'

export const artistExporter = (records) => {
  const recordsForExport = records.map((post) => {
    const {
      // omit fields:
      id,
      vita_short,
      vita_long,
      info,
      portrait_file,
      previewImage,
      done21,
      done212,
      join20222,
      join20231,
      join20241,
      consentGTC,
      consentContract,
      consentInsurance,
      userAgent,
      // createdAt,
      // updatedAt,
      updatedby,
      lastupdate,
      ...rest
    } = post
    return rest
  })

  exportToXLSX(
    recordsForExport.sort((a, b) => a.last_name.localeCompare(b.last_name)),
    [
      // order fields in the export
      'last_name',
      'first_name',
      'pseudonym',
      'artistId',
      'visible',
      'birthday',
      'address',
      'zipcode',
      'city',
      'mobile',
      'phone',
      'email',
      'homepage',
      'instagram',
      'account_holder',
      'iban',
      'bic',
      'bank_name',
      'tax_number',
      'tax_office',
      'liability',
      // 'consentGTC_20231',
      'createdAt',
      'updatedAt',
    ],
    'Künstler',
    `KUBOSHOW 2024-1 GDT Künstler ${new Date().toISOString()}`
  )
}

const VitaField = ({ record, source }) => (
  <span>{record[source].substr(0, 10) + '...'}</span>
)

export const ArtistListFilter = (props) => (
  <Filter {...props}>
    <BooleanInput label="Teilnehmer 2024-1" source="join20241" />
    <BooleanInput label="Teilnehmer 2023-1" source="join20231" />
    {/* <BooleanInput label="Teilnehmer 2022-2" source="join20222" />
    <BooleanInput label="Teilnehmer 2022-1" source="join20221" /> */}
  </Filter>
)

const ArtistList = (props) => (
  <List
    perPage={200}
    filters={<ArtistListFilter />}
    filterDefaultValues={{ join20241: true, join20231: false }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    exporter={artistExporter}
    {...props}
  >
    {/* <Datagrid rowClick="expand" expand={<ArtistShow />}> */}
    <Datagrid rowClick="edit">
      {/* <TextField disabled source="id" /> */}
      <ImageField source="portrait_file" label="Portraitfoto" />
      <TextField disabled source="artistId" label="Künstler-ID" />
      <OnlineOfflineField label="On-/Offline" source="visible" />
      <TextField source="last_name" label="Nachname" />
      <TextField source="first_name" label="Vorname" />
      <TextField source="pseudonym" label="Künstlername" />
      {/* <ReferenceManyField
        label="Arbeiten"
        reference="artworks"
        target="artistId"
        >
        <SingleFieldList>
        <ChipField source="title" />
        </SingleFieldList>
      </ReferenceManyField> */}
      {/* <TextField source="birthday" label="Geburtstag" /> */}
      <EmailField source="email" label="E-Mail" />
      {/* <BooleanField label="Vertrag akzp." source="consentGTC_20231" /> */}
      {/* <BooleanField label="Versich. y/n" source="consentInsurance" />
      <BooleanField label="Vertrag y/n" source="consentContract" /> */}
      {/* <TextField source="homepage" label="Homepage" /> */}
      <DateField source="createdAt" label="erstellt" showTime={true} />
      <DateField
        source="updatedAt"
        label="letzte Aktualisierung"
        showTime={true}
      />
      {/* <TextField source="address" label="Straße & Nr." />
      <TextField source="zipcode" label="PLZ" />
      <TextField source="city" label="Stadt" />
      <TextField source="mobile" label="Handy" />
      <TextField source="phone" label="Festnetz" />
      <VitaField source="vita_short" label="Kurzvita" />
      <VitaField source="vita_long" label="Vita" />
      <VitaField source="info" label="Info" /> */}
    </Datagrid>
  </List>
)

export default ArtistList
