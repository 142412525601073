import * as React from 'react'
import { forwardRef } from 'react'
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/core/styles'
import { downloadFolderAsZip } from '../shared/bigExport'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
})

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate()
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate('pos.configuration')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  )
})
ConfigurationMenu.displayName = 'ConfigurationMenu'

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
)

const CustomAppBar = (props: any) => {
  const classes = useStyles()
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      >
        KUBOSHOW GDT
      </Typography>
      <Typography
        component="button"
        variant="h5"
        onClick={() => downloadFolderAsZip()}
      >
        EXP
      </Typography>
    </AppBar>
  )
}

export default CustomAppBar
