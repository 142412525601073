import * as XLSX from 'xlsx'

export const initBlobDownload = (blob, filename, extension = '.txt') => {
  const fakeLink = document.createElement('a')
  fakeLink.style.display = 'none'
  document.body.appendChild(fakeLink)

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob))
    fakeLink.setAttribute('download', filename + extension)
    fakeLink.click()
  }
}

export const exportToXLSX = (jsonData, order = [], sheetName, fileName) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

  const ws = XLSX.utils.json_to_sheet(jsonData, {
    header: order,
  })
  const wb = { Sheets: { artists: ws }, SheetNames: ['artists'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const blobData = new Blob([excelBuffer], { type: fileType })

  initBlobDownload(blobData, fileName, '.xlsx')
}

export const kbImagifyOrig = (filename) =>
  'https://imgs.kuboshow.de/images%2F' + encodeURIComponent(filename)

export const kbImagifyDyn = (filename, width = 320) =>
  `https://imgs.kuboshow.de/cdn-cgi/image/width=${width},format=auto/images%2F${filename}`

export const kbImagifyDynOld = (filename, width = 320) =>
  'https://images.weserv.nl/?url=imgs.kuboshow.de/images%2F' +
  filename +
  '&w=' +
  width

export const artistDisplayName = (artist) => {
  if (artist.pseudonym && artist.pseudonym.length > 0) {
    return artist.pseudonym
  } else {
    return artist.first_name + ' ' + artist.last_name
  }
}

export const getInstaName = (artist) => {
  if (!artist.instagram) return ''
  if (artist.instagram.startsWith('@')) {
    return artist.instagram
  } else {
    return '@' + artist.instagram.replace(/\s/g, '')
  }
}
export const getArtworkDimension = (artwork) =>
  artwork.width +
  ' x ' +
  artwork.height +
  (artwork.depth ? ` x ${artwork.depth}` : '') +
  ' cm'

export const calcPrice = (artwork) =>
  artwork.portoActive
    ? Number(artwork.price) + Number(artwork.porto)
    : Number(artwork.price)

export const getArtworkUnderlineAsString = (artwork, hidePrice = false) =>
  artwork.title +
  ' | ' +
  artwork.technique +
  ' | ' +
  getArtworkDimension(artwork) +
  (artwork.price && !hidePrice ? ` | ${calcPrice(artwork)} €` : '') +
  (artwork.info ? ` | ${artwork.info}` : '')

const hashtags =
  '#kuboshow #Kunstmesse #artfair #Malerei #Kunsthandel #artcollector #artcontemporary #kunstmarkt #jungekunst #painting #figurativepainting #artist #contemporaryart #Kunstsammler'

export const getInstaText = (artist, artwork) =>
  `${artistDisplayName(artist)} | ${artwork.title}, ${
    artwork.technique
  }, ${getArtworkDimension(artwork)}  ${getInstaName(artist)} ${hashtags}`

export const copyTextToClipboard2 = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

export const groupByKey = (list, key) =>
  list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  )
