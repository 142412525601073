import * as React from 'react'
import { Box } from '@material-ui/core'
import {
  // List,
  // Create,
  // Datagrid,
  DateField,
  TextField,
  RichTextField,
  SimpleShowLayout,
  Show,
  // ChipField,
  // ReferenceField,
  // ReferenceManyField,
  // SingleFieldList,
  // EditButton,
  // Edit,
  // SelectInput,
  // TextInput,
  // ReferenceInput,
  // SimpleForm,
  // Tab,
} from 'react-admin'

const ArtistTitle = ({ record }) => (
  <span>{`${record.last_name} ${record.first_name}` || ''}</span>
)

const ArtistShow = (props) => (
  <Show title={<ArtistTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" label="eingegangen" />
      <Box display="flex">
        <TextField source="last_name" label="Nachname" />
        <TextField source="first_name" label="Vorname" />
        <TextField source="pseudonym" label="Künstlername" />
      </Box>
      <TextField source="email" label="E-Mail" />
      <TextField source="homepage" label="Homepage" />
      <TextField source="birthday" label="Geburtstag" />
      <TextField source="address" label="Straße & Nr." />
      <TextField source="zipcode" label="PLZ" />
      <TextField source="city" label="Stadt" />
      <TextField source="mobile" label="Handy" />
      <TextField source="phone" label="Festnetz" />
      <RichTextField source="info" label="Info" />
      <RichTextField source="vita_short" label="Kurzvita" />
      <RichTextField source="vita_long" label="Vita" />
    </SimpleShowLayout>
  </Show>
)

export default ArtistShow
